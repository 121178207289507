.Home {
    display: flex;
    flex-direction: column;
    gap: 4vw;
    background-color: var(--background);
    /*background-image: url("imgs/first.png");*/
}

#results {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    z-index: 1;
}

#no-result {
    background-color: white;
    margin-left: 10%;
    width: fit-content;
    padding: 5%;
    border-radius: 10px;

}

@media only screen and (min-width: 1200px) {
    .Home {
        width: 54%;
        margin-left: 23%;
    }
    body {
        background-color: white;
    }
 }