  .search-result-container {
    padding: 0;
    width: 60%;/*match*/
    margin-left: 20%;/*match*/
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10%;
    border-radius: 10px 0px 10px 10px;
    z-index: 1;
    position: relative;
}

.text {
    padding: 0 5%;
}

div.text > p { /*to not crowd all paragraphs on search results*/
    line-height: 150%;
}

.search-result-img {
    width: calc(100px + 5vw);
    height: auto;
    border-radius: 10px;
}

.tab-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 60%; /*match*/
    margin-left: 20%; /*match*/
    column-gap: 1%;
    padding: 0;
    margin-bottom: 0;
}

.tab {
    padding: 2px;
    border: 2px solid white;
    font-size: calc(8px + 1vw);
    color: var(--darkgray);
    background-color: white;
    border-radius: 5px 5px 0 0;
    list-style: none;
}

.tab:hover {
    cursor: pointer;
    background: white;
}

.react-tabs__tab--selected {
    color: var(--almostblack);
    border-bottom: 1px solid var(--almostblack);
  }

.react-tabs__tab-panel--selected {
    animation-name: fade-in;
    animation-duration: 250ms;
    animation-timing-function: linear;
}

@keyframes fade-in {
    from { 
        opacity: 0.5; 
    } 
    to { 
        opacity: 1; 
    } 
}

@media only screen and (max-width: 600px), (min-width: 1200px) {
    .search-result-container {
        column-gap: 5%;
        width: 80%;
        margin-left: 10%;
    }

    .tab-list {
        width: 80%;
        margin-left: 10%;

    }  
}

@media only screen and (min-width: 1200px) {
    .tab {
        font-size: calc(8px + 0.7vw);
    }
}

 