
.heading-container {
    background-color: var(--yellow);
    max-height: fit-content;
}
.menu-container {
    padding: 2%;
}

.menu-icon {
  font-size: calc(10px + 2vw);
  color: var(--almostblack);
}

#menu-button { /*remove default styling from burger menu*/
    -webkit-appearance: none;
    border:  none;
    background: none;
    cursor: pointer;
}

.menu-dropdown {
    background-color: rgba(256, 256, 256, 0.5);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    position: absolute;
    width: 10%;
  }

.menu-dropdown ul {
    font-family: var(--font2);
    font-size: calc(8px + 1vw);
    list-style: none;
    padding: 0; /*to remove bullet space*/
    margin: 0; /*due to absolute position change*/
}  

.menu-dropdown li {
    padding: 10%;
}

.menu-dropdown li:hover {
    background-color: var(--yellow);
}

.heading-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

#heading-img {
    width: calc(30px + 2.5vw);
    height: auto;
}

#wave {
    margin-top: -1px; /*gap on tablet*/
    width: 100%;
}

#wave path {
   fill: var(--yellow);
}

@media only screen and (max-width: 500px) {
    .menu-dropdown {
        width: 13%;
    }

}

@media only screen and (min-width: 1200px) {
    .menu-dropdown {
        width: 7%;
    }

    .menu-dropdown ul {
        font-size: calc(8px + 0.8vw);
    }
}