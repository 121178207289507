.footer-container {
    background-color: var(--purple);
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 50%;
    border-top-left-radius: 90%;
    transform: skewY(12deg);
    height: 35vw;
    width: 40%;
    margin-left: 55%;
    display: flex;
    margin-top: -15%;
    z-index: 0;
}

.text-container {
    transform: skewY(-12deg); /*unskew*/
    align-self: flex-end; /*move text to bottom*/
}

@media only screen and (min-width: 1200px) {
    .footer-container {
        height: 20vw;
        margin-bottom: 8%;
    }
}
