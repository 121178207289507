@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Abril+Fatface&family=Josefin+Sans&display=swap');

:root {
  --background: rgb(247, 238, 226);
  --darkgray: rgb(100, 100, 100);
  --yellow: rgba(254, 232, 112);
  --almostblack:  rgba(35, 35, 35, 1);
  --blue: rgba(160, 218, 244);
  --lightblue: rgba(194, 228, 243);
  --darkblue: rgb(125, 209, 248);
  --green: rgba(185, 232, 112, 1);
  --purple: rgba(205, 191, 231, 1);
  --faintpurple: rgba(205, 191, 231, 0.5);
  --font1: 'Fredericka the Great', 'Abril Fatface', serif;
  --font2: 'Josefin Sans', sans-serif;
}

body {
  color: var(--almostblack);
  background-color: var(--background);
	padding: 0;
	margin: 0;
  font-family: var(--font2);
  font-size: calc(8px + 1vw);
} 

h1 {
  font-family: var(--font1);
  font-size: calc(12px + 2.5vw);
  font-weight: normal;
  text-shadow: 0px 1px var(--almostblack); /*fake bolding, with bold font looks too dark on chrome, edge*/
}

h2 {
  font-family: var(--font1);
  font-size: calc(12px + 2vw);
  font-weight: normal;
  text-shadow: 0px 1px var(--almostblack);
}

h3 {
  font-size: calc(12px + 1vw);
}

.small-par {
  font-size: calc(6px + 1vw);
}

button {
  color: var(--almostblack);
  font-family: var(--font2);
  font-size: calc(8px + 1vw);
}

a {
  text-decoration: none;
  color: var(--almostblack);
}

#ogonek {
  letter-spacing: -1px; /*otherwise diacritics might be joined onto letters, which fallback on font*/
}

#overdot {
  font-size: calc(12px + 2.5vw);
  letter-spacing: -20px;
}

@media only screen and (max-width: 500px) {
  h1, h2 {
    text-shadow: none; /*shadowing looks blurry on small screen*/
    font-weight: bold;
  }
}

@media only screen and (min-width: 1200px) {

  body, .small-par {
    font-size: calc(8px + 0.7vw);
  } 

  h1 {
    font-size: calc(12px + 2vw);
  }
  
  h2 {
    font-size: calc(12px + 1.5vw);
  }
  
  h3 {
    font-size: calc(8px + 1vw);
  }

  button {
    font-size: calc(8px + 0.7vw);
  }

  #overdot {
    font-size: calc(12px + 2.2vw);
  }
}