
.color-container {
    background-color: var(--blue);
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    border-bottom-left-radius: 40%;
    border-top-left-radius: 50%;
    transform: skewY(12deg);
    height: fit-content;
    width: 45%;
    margin-top: -15%;
    z-index: 2;
}

.search-container {
    margin-top: -5%;
    padding-left: 7%;
    transform: skewY(-12deg); /*unskew*/
}

  #filter-button { /*remove default styling*/
    -webkit-appearance: none;
    border:  none;
    background: none;
    cursor: pointer;
}

label input { /*hide default checkbox*/
    display: none;
}

.filter-container {
    display: flex;
    flex-direction: column;
    padding-top: 5%;
}

.filters {
    padding-top: 5%;
    padding-bottom: 5%;
}

#search-button { /*remove default styling*/
    -webkit-appearance: none;
    border: none;
    background: none;
    background-color: var(--lightblue);
    padding: 10%;
    margin-top: 5%;
    cursor: pointer;
    border-radius: 30% 60% 40% 70%; 
}

#empty {
    height: 2vw;
}

/*react autocomplete component doesn't support single border styling, width*/
.input-autocomplete {
    width: 80%;
    border-bottom: 1px solid var(--almostblack);
    position: relative;
}

@media only screen and (max-width: 450px) {
    .color-container {
        width: 70%;
    }

}

@media only screen and (min-width: 1200px) {
    .color-container {
        width: 60%;
    }

    .input-autocomplete {
        width: 70%;
    }
}